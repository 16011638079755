import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import { Link } from "gatsby"

import Background from "../../../assets/images/servicesHeader/web_icon.svg"

import drupal from "../../../assets/images/servicesPages/drupal2.png"
import acquia from "../../../assets/images/servicesPages/acquia.png"
import arrow from "../../../assets/images/portfolio_items/idday/arrow-back.png"

import unisg from "../../../assets/images/cms/unisg.png"
import cs from "../../../assets/images/cms/cs.png"
import pf from "../../../assets/images/cms/pf.png"
import vifor from "../../../assets/images/cms/vifor.png"
import idday from "../../../assets/images/cms/idday.png"
import img from "../../../assets/images/cms/img.png"
import chart from "../../../assets/images/cms/chart.png"
import badge from "../../../assets/images/cms/badgeDrupal.png"

class Drupal extends React.Component {

  
    render() {
      return (
    <div id="cms">
        <SEO title={'Acquia and Drupal Development | ICON Worldwide'} 
        description="Whether you need to hire a Drupal developer or complete an end-to-end Drupal Development project, ICON is the solution you're seeking!"
        canonical={'https://icon-worldwide.com/services/acquia-and-drupal-development'}/>
        <Header headerTitle="Drupal" headerSubTitle="We are Expert Drupal 8 Developers and Acquia certified"  headerBackground={Background}/>
        <div id="drupal">
            <div className="cms-inner">
                <div className="left-part">
                    <h1>Struggling with Acquia or Drupal Development? We have the solution for you!</h1>
                    <p className="bigText">Whether you need to hire a Drupal developer to augment your current team, or are looking for a complete end-to-end Drupal development
                    solution to be delivered, <span>we can meet your needs.</span> Our Drupal team is <span>certified</span>, with <span>10 plus years</span> experience, and one of the largest
                    in Europe.</p>
                    <h2>Why Drupal</h2>
                    <p>Drupal is an open source PHP based CMS that gives companies the ability to create robust website platforms on a stable and secure infrastructure. Drupal is ideal for
                    everything from community platforms, content management intensive sites to e-commerce platforms.</p>
                    <p>Drupal enables organizations to build better sites and experiences faster.Global enterprises, brands, governments, colleges and universities, NGOs and other organizations
                    use Drupal so they can engage with users on websites and beyond, to deliver the right content,to the right users, at the right time.</p>
                </div>
                <div className="right-part">
                    <div className="right-inner">
                        <img src={drupal} alt="ICON Worldwide drupal development" title="ICON Worldwide drupal development"/>
                        <h3>Are you considering a Drupal build?</h3>
                        <p>Talk to us, we’ll be happy to walk you through the pros and cons of Drupal for your project.</p>
                        <div className="bigger-space">
                            <h3>Our staffing models</h3>
                            <p>We offer three models of cooperation:</p>
                            <h4>Project based</h4>
                            <h4>Time and materials</h4>
                            <h4>Dedicated team</h4>
                            <Link to="/contact-us"><div className="contact-btn">Let's start your new project together</div></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="silver-bg">
                <div className="cms-inner">
                    <div className="part">
                        <img src={acquia} className="acquia-img" alt="ICON Worldwide drupal development and acquia developers" title=" ICON Worldwide drupal development, acquia developers"/>
                        <h2 className="special">Acquia</h2>
                        <p>As an official Acquia partner our development team is certified in Drupal 8 development on Acquia.
                            Acquia is a hosting platform designed exclusively for the Drupal CMS. Acquia provides the leading cloud
                            platform for building, delivering, and optimizing digital experiences. Acquia is ideal for global organisations
                            seeking to centralise content, security and compliance via a central code base</p>
                    </div>
                    <div className="part">
                        <h2>Enterprise Security</h2>
                        <p>We maintain Drupal platforms for global enterprises working in highly regulated industries, such as finance and pharma.
                            This ensures you can count us to develop the most efficient code and the most secure platforms. Our platforms support
                            thousands of user interactions and transactions daily.</p>
                    </div>
                </div>
            </div>
            <div className="cms-inner">
                <div className="part">
                    <h2>Sustainable Development and Investment</h2>
                    <p>Beyond the initial development, our teams are on-board for continuous support and expansion of functionalities as needed.
                        We have built Drupal websites which have been in continuous operation for more than 10 years. For some of our clients we
                        serve as webmasters, providing on-demand support. For others, we manage a transition to an internal IT team.</p>
                        <img src={badge} alt="ICON Worldwide badge, drupal development " title=" ICON Worldwide drupal development badge" className="badge"/>
                        <Link to="/works"><span>References <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/></span></Link>
                        <div className="logos">
                        <Link to="/works/university-of-st-gallen"><img src={unisg} alt="ICON Worldwide drupal development" title="ICON Worldwide drupal development unisg"/></Link>
                        <Link to="/works/womens-football/"><img src={cs} alt="ICON Worldwide drupal development" title=" ICON Worldwide drupal development cs"/></Link>
                        <Link to="/works/project-firefly"> <img src={pf} alt="ICON Worldwide drupal development" title="ICON Worldwide drupal development pf"/></Link>
                        <Link to="/works/vifor-pharma"><img src={vifor} alt="ICON Worldwide drupal development" title="ICON Worldwide drupal development vifor"/></Link>
                        <Link to="/works/iron-deficiency-day"><img src={idday} alt="ICON Worldwide drupal development" title="ICON Worldwide drupal development idday"/></Link>
                        </div>
                </div>
                <div className="part">
                    <img className="full-img" src={img} alt="ICON Worldwide drupal developmen" title="ICON Worldwide  drupal development img"/>
                </div>
            </div>
            <div className="silver-bg">
                <div className="cms-inner">
                    <h3>Enterprise Drupal Development on Acquia</h3>
                    <ul>
                        <li>Faster development and launches</li>
                        <li>Reduced maintenance overhead</li>
                        <li>Centralised platform security updates</li>
                        <li>Faster security updates across the organisation</li>
                        <li>Improved compliance (GDPR)</li>
                        <li>Security clearance across a single development team</li>
                        <li>Reduced code waste via common code base</li>
                        <li>Reduced redundancy risk via coding standards</li>
                        <li>Corp UI, and shared components reduce development time and cost</li>
                        <li>Consolidated data analytics and reporting</li>
                        <li>Consistent global translations and approvals</li>
                    </ul>
                </div>
            </div>
            <div className="cms-inner last-inner">
                <div className="part">
                    <h3>Benefits of a central codebase:</h3>
                    <ul>
                        <li>Blocks, views, and content structures of a platform work similar to an API to the front-end theme (website)</li>
                        <li>Individual sites can theme these modules in different ways without needing to update or vary the underlying platform code.</li>
                    </ul>
                    <div className="bigger-space">
                        <h3>Benefits of central hosting on the cloud</h3>
                        <ul>
                            <li>Top speed worldwide via Content Deliver Network (mirror hosting) </li>
                            <li>Single control panel and admin control of all sites </li>
                            <li>Efficient workflows and deployment</li>
                        </ul>
                    </div>
                </div>
                <div className="part">
                    <h3>Benefits of a central codebase:</h3>
                    <ul>
                        <li>When a new feature is proposed by the business the tech team evaluates and decides how best to incorporate the feature within the entire website universe</li>
                        <li>So that each new feature is offered to the full set</li>
                        <li>And each new feature is compliant with the full set</li>
                    </ul>
                    <div className="bigger-space">
                        <img src={chart} alt="ICON Worldwide chart, drupal development" title="ICON Worldwide drupal development chart" className="chart"/>
                    </div>
                </div>
                <div className="yellowLine"></div>
                <ServicesBottomNav/>
            </div>
            </div>
        <Footer noScrollbar="true"/>
    </div>
    )
}
}

export default Drupal 